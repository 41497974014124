import { useSearchContext } from '@contexts/SearchProvider';
import { useReducer } from 'react';

interface SearchData {
  publishDate: [moment.Moment | null, moment.Moment | null] | undefined;
  expireDate: [moment.Moment | null, moment.Moment | null] | undefined;
  type: string;
  securityType: string;
  status: string;
  name: string;
  description: string;
  url: string;
  newUrl: string;
  serverId: string;
  company: string;
  companyType: string;
  loginName: string;
  email: string;
  roleId: string;
  handleCalendarChange: (
    values: any,
    formatString: [string, string],
    fieldName?: string
  ) => void;
  handleDateChange: (
    values: any,
    formatString: [string, string],
    fieldName?: string
  ) => void;
  handleSearch: () => void;
  handleReset: () => void;
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => void;
  handleSelectChange: (value: string, type: string) => void;
}

const ACTIONS = {
  RESET: 'reset',
};

const initialState: SearchState = {
  begin: null,
  end: null,
  beginExpire: null,
  endExpire: null,
  status: '',
  publishDate: [null, null],
  expireDate: [null, null],
  type: '',
  securityType: '',
  name: '',
  description: '',
  url: '',
  newUrl: '',
  serverId: '',
  company: '',
  companyType: '',
  loginName: '',
  email: '',
  roleId: '',
};

const reducer = (
  state: SearchState,
  action: {
    type: string;
    securityType?: string;
    status?: string;
    publishDate?: [moment.Moment | null, moment.Moment | null];
    expireDate?: [moment.Moment | null, moment.Moment | null];
    begin?: string;
    end?: string;
    beginExpire?: string;
    endExpire?: string;
    name?: string;
    description?: string;
    url?: string;
    newUrl?: string;
    serverId?: string;
    company?: string;
    companyType?: string;
    loginName?: string;
    email?: string;
    roleId?: string;
  }
) => {
  const {
    type,
    securityType,
    status,
    publishDate,
    expireDate,
    begin,
    end,
    beginExpire,
    endExpire,
    name,
    description,
    url,
    newUrl,
    serverId,
    company,
    companyType,
    loginName,
    email,
    roleId,
  } = action;

  switch (type) {
    case 'status': {
      return { ...state, ...{ status: status || '' } };
    }

    case 'publishDate': {
      return {
        ...state,
        ...{
          publishDate: publishDate || [null, null],
          begin: begin || null,
          end: end || null,
        },
      };
    }

    case 'expireDate': {
      return {
        ...state,
        ...{
          expireDate: expireDate || [null, null],
          beginExpire: beginExpire || null,
          endExpire: endExpire || null,
        },
      };
    }

    case 'securityType': {
      return { ...state, ...{ securityType: securityType || '' } };
    }

    case 'type': {
      return { ...state, ...{ type: type || '' } };
    }

    case 'name': {
      return { ...state, ...{ name: name || '' } };
    }

    case 'url': {
      return { ...state, ...{ url: url || '' } };
    }

    case 'newUrl': {
      return { ...state, ...{ newUrl: newUrl || '' } };
    }

    case 'description': {
      return { ...state, ...{ description: description || '' } };
    }

    case 'loginName': {
      return { ...state, ...{ loginName: loginName || '' } };
    }

    case 'email': {
      return { ...state, ...{ email: email || '' } };
    }

    case 'roleId': {
      return { ...state, ...{ roleId: roleId || '' } };
    }

    case 'serverId': {
      return { ...state, ...{ serverId: serverId || '' } };
    }

    case 'company': {
      return { ...state, ...{ company: company || '' } };
    }

    case 'companyType': {
      return { ...state, ...{ companyType: companyType || '' } };
    }

    case ACTIONS.RESET:
      return { ...state, ...initialState };

    default:
      return state;
  }
};

interface SearchState {
  begin: string | null;
  end: string | null;
  beginExpire: string | null;
  endExpire: string | null;
  status: string;
  publishDate: [moment.Moment | null, moment.Moment | null];
  expireDate: [moment.Moment | null, moment.Moment | null];
  type: string;
  securityType: string;
  name: string;
  description: string;
  url: string;
  newUrl: string;
  serverId: string;
  company: string;
  companyType: string;
  loginName: string;
  email: string;
  roleId: string;
}

export const useSearchPagination = (): SearchData => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { queryParam, handleQueryParam } = useSearchContext();

  const handleSearch = () => {
    const param = filterParam(state);
    handleQueryParam({ ...param, page: 1 });
  };

  const handleReset = () => {
    dispatch({ type: ACTIONS.RESET });
  };

  const handleDateChange = (
    values: any,
    formatString: [string, string],
    fieldName = 'publishDate'
  ) => {
    const [begin, end] = formatString || ['', ''];
    dispatch({
      type: fieldName,
      [fieldName]: values,
      begin,
      end,
    });
  };

  const handleCalendarChange = (
    values: [moment.Moment, moment.Moment],
    formatString: [string, string],
    fieldName = 'publishDate'
  ) => {
    const [beginExpire, endExpire] = formatString || ['', ''];
    dispatch({
      type: fieldName,
      [fieldName]: values,
      beginExpire,
      endExpire,
    });
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ): void => {
    dispatch({ type: fieldName, [fieldName]: event.target.value });
  };

  const handleSelectChange = (value: string, fieldName: string): void => {
    dispatch({ type: fieldName, [fieldName]: value });
  };

  const filterParam = (param: SearchState) => {
    const result: { [key: string]: unknown } = {};
    for (const [key, value] of Object.entries(param)) {
      const keys = Object.keys(queryParam);
      if (keys.includes(key)) {
        result[key] = value === '' || value === false ? null : value;
      }
    }
    return result;
  };

  return {
    publishDate: state.publishDate,
    expireDate: state.expireDate,
    type: state.type,
    securityType: state.securityType,
    status: state.status,
    name: state.name,
    description: state.description,
    url: state.url,
    newUrl: state.newUrl,
    serverId: state.serverId,
    company: state.company,
    companyType: state.companyType,
    loginName: state.loginName,
    email: state.email,
    roleId: state.roleId,
    handleCalendarChange,
    handleDateChange,
    handleSearch,
    handleReset,
    handleInputChange,
    handleSelectChange,
  };
};
