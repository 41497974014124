import { PageNotFound } from '@components/PageNotFound';
import { PermissionDeny } from '@components/PermissionDeny';
import { DefaultLayout } from '@layouts/DefaultLayout';
import { HomeLayout } from '@layouts/HomeLayout';
import { Layouts } from '@layouts/Layouts';
import { PermissionLayout } from '@layouts/PermissionLayout';
import { AccessTime } from 'pages/access-time';
import { Attention } from 'pages/attention';
import { AttentionApprove } from 'pages/attention-approve';
import { AttentionView } from 'pages/attention-view';
import { ConfigCreator } from 'pages/config-creator';
import { DcaConfig } from 'pages/dca-config';
import { Domain } from 'pages/domain';
import { Function } from 'pages/function';
import { Home } from 'pages/Home';
import { MdfConfig } from 'pages/mdf-config';
import { Menu } from 'pages/menu';
import { PortAssign } from 'pages/port-assign';
import { Role } from 'pages/role';
import { ManageRoleSupport } from 'pages/manage-role-support';
import { SystemConfig } from 'pages/system-config';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CheckAccessTimeRoute from './CheckAccssTimeRoute';
import ProtectedRoute from './ProtectedRoute';
import { SecurityStatusConfig } from 'pages/security-status-config';

const routes = [
  {
    path: '/access-time',
    exact: true,
    component: AccessTime,
    layout: DefaultLayout,
  },
  {
    path: '/system-configs',
    exact: true,
    component: SystemConfig,
    layout: DefaultLayout,
  },
  {
    path: '/domain',
    exact: true,
    component: Domain,
  },
  {
    path: '/port-assigns',
    exact: true,
    component: PortAssign,
  },
  {
    path: '/attentions',
    exact: true,
    component: Attention,
  },
  {
    path: '/attentions/approve',
    exact: true,
    component: AttentionApprove,
  },
  {
    path: '/attentions/view',
    exact: true,
    component: AttentionView,
  },
  {
    path: '/roles',
    exact: true,
    component: Role,
  },
  {
    path: '/function',
    exact: true,
    component: Function,
  },
  {
    path: '/menu',
    exact: true,
    component: Menu,
  },
  {
    path: '/mdf-configs',
    exact: true,
    component: MdfConfig,
  },
  {
    path: '/dca-configs',
    exact: true,
    component: DcaConfig,
  },
  {
    path: '/test-creator-configs',
    exact: true,
    component: ConfigCreator,
  },
  {
    path: '/manage-role-support',
    exact: true,
    component: ManageRoleSupport,
  },
  {
    path: '/security-status-configs',
    exact: true,
    component: SecurityStatusConfig,
  },
];

export const Routers: React.FC = () => (
  <Switch>
    <Route path={['/auth', '/']} exact>
      <Layouts layout={HomeLayout} isPublic={true}>
        <Route component={Home} />
      </Layouts>
    </Route>

    <Layouts layout={DefaultLayout} isPublic={true}>
      {routes.map((r) => {
        return (
          <CheckAccessTimeRoute
            key={r.path}
            path={r.path}
            component={r.component}
            exact={r.exact}
          />
        );
      })}
      <ProtectedRoute path={'/direct/*'} exact />
      <Route path={'/error/access'} exact component={PermissionDeny} />
    </Layouts>

    <Route path="*">
      <Layouts layout={PermissionLayout} isPublic={true}>
        <Route component={PageNotFound} />
      </Layouts>
    </Route>
  </Switch>
);
export default routes;
